import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/components/index.vue'),
    },
    {
        path: '/lookup-tree',
        name: 'lookup-tree',
        component: () => import('@/components/tree.vue'),
        children: [
            {
                path: ':category',
                name: 'tree-category',
                component: () => import('@/views/category.vue'),
            },
            {
                path: ':category/:id',
                name: 'category-detail',
                component: () => import('@/views/category-detail.vue'),
            },
            
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
